(function($) {
    'use strict';

    /**
     * Auto grow text areas
     */
    function autoresize(textarea) {
        if ($(textarea).is(':visible')) {
            textarea.style.minHeight = '0px';
            textarea.style.minHeight = textarea.scrollHeight + 'px';
        }
    }
    $('textarea').each(function() {
        autoresize(this);
        $(this).keyup(function(event) {
            autoresize(this);
        });
    });


    /**
     * Focus of widgets
     */
    $('form .widget.hide-label').each(function() {
        let $widget = $(this);
        let val = $widget.find('input,textarea,select').val();

        if (val) {
            $widget.addClass('show-label');
        }

        $widget.find('input,textarea').on('input', function() {
            if ($(this).val()) {
                $widget.addClass('show-label');
            } else {
                $widget.removeClass('show-label');
            }
        });

        $widget.find('select').on('change', function() {
            if ($(this).val()) {
                $widget.addClass('show-label');
            } else {
                $widget.removeClass('show-label');
            }
        });

        $widget.find('input,textarea,select').on('focus', function() {
            $widget.addClass('focus');
        });

        $widget.find('input,textarea,select').on('focusout', function() {
            $widget.removeClass('focus');
        });

        $widget.find('input,textarea,select').on('blur', function() {
            if (!$(this).val()) {
                $widget.removeClass('show-label');
            }
        });
    });


    /**
     * Chosen class for radio and checkbox
     */
    $('form .widget-radio, form .widget-checkbox').each(function() {
        let $widget = $(this);
        let $inputs = $widget.find('input');
        let updateChosen = function() {
            if ($inputs.is(':checked')) {
                $widget.addClass('chosen');
            } else {
                $widget.removeClass('chose');
            }  
        }
        updateChosen();
        $inputs.on('change', updateChosen);
    });


    /**
     * Validate form
     */
    $('form').each(function() {
        let $form = $(this);
        let $inputs = $form.find('input,select,textarea');

        let change = function() {
            $form.addClass('valid');
            $inputs.each(function() {
                this.checkValidity();
            });
        };

        $inputs.on('input', change)
                .on('change', change)
                .bind('invalid', function() {
            $form.removeClass('valid');
        });
    });

})(jQuery);
